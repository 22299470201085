import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import SinglePage from "../components/common/SinglePage"

export default function PageTemplate({ data }) {
  return (
    <div className="">
      <Seo title={data.wpPage.title} />
      <Layout>
        <SinglePage page={data.wpPage} />
      </Layout>
    </div>
  )
}

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
    }
  }
`
