import React from "react"

export default function SinglePage({ page }) {
  const { content, title } = page
  return (
    <div className="max-w-4xl mx-auto py-16 px-4 md:px-8">
      {" "}
      <div className="space-y-8">
        <h1 className=" font-sans text-4xl font-bold tracking-tight leading-normal text-brand">
          {title}
        </h1>
        <div
          dangerouslySetInnerHTML={{ __html: content }}
          className="space-y-4"
        />
      </div>
    </div>
  )
}
